import React from "react"
import styled from "styled-components"
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import NewsletterCTA from '../components/NewsletterCTA'

const OpeningParagraph = styled.section`
  margin-bottom: 50px;
  
  .container {
    width: 100%;
    max-width: 850px;
    margin: 0 auto 50px auto;
  }

  h2 {
    text-align: center;
  }

  p {
    color: var(--gray);
    text-align: center;
    margin-bottom: 0;
  }
`

const PartnersSection = styled.section`
  margin-bottom: 100px;

  .container {
    max-width: 850px;
    margin: 0 auto;

    .partner-grid {
      margin-bottom: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 100px;

      img {
        filter: saturate(0) brightness(8);
        object-fit: contain !important;
        width: 100%;
        max-width: 200px;
        height: auto;
        max-height: 110px;
      }
    }
  }

  .partners-contact {
    text-align: center;
  }
`

const MembersSection = styled.section`
  margin-bottom: 100px;

  .container {
    max-width: 850px;
    margin: 0 auto;

    .members-grid {
      margin-bottom: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 100px;

      img {
        filter: saturate(0) brightness(8);
        object-fit: contain !important;
        width: 100%;
        max-width: 200px;
        height: auto;
        max-height: 110px;
      }
    }
  }

  .members-contact {
    text-align: center;
  }
`

const Partnerships = (props) => {

  return (
    <main>
      {props.data.partnershipPageData.edges.map(({ node }) => (
        <PageTitle key={node.id} title={node.pageTitle} paragraph={node.pageTitleParagraph.pageTitleParagraph} />
      ))}
      <OpeningParagraph>
        {props.data.partnershipPageData.edges.map(({ node }) => (
          <div className="container" key={node.id}>
            <h2>Our Partnerships</h2>
            <p>{node.openingParagraph.openingParagraph}</p>
          </div>
        ))}
      </OpeningParagraph>
      <PartnersSection>
        <div className="container">
          {props.data.partners.edges.map(({ node }) => (
            <div key={node.id} className="partner-grid">
              {node.PartnershipsOrder && node.PartnershipsOrder.map((item) => (
                <div key={item.contentful_id}>
                  <a href={item.partnerUrl} target="_blank" rel="noopener noreferrer">
                    <GatsbyImage image={item.logo.gatsbyImageData} alt={item.logo.title} />
                  </a>
                </div>
              ))}
            </div>
          ))}
        </div>
        {props.data.partnershipPageData.edges.map(({ node }) => (
          <div className="partners-contact" key={node.id}>
            {/* <Link to={node.contactButtonUrl} className="btn btn-large">{node.contactButtonText}</Link> */}
            <a href={node.contactButtonUrl} className="btn">{node.contactButtonText}</a>
          </div>
        ))}
      </PartnersSection>
      <OpeningParagraph>
        {props.data.partnershipPageData.edges.map(({ node }) => (
          <div className="container" key={node.id}>
            <h2>Our Memberships</h2>
            <p>{node.membershipParagraph.membershipParagraph}</p>
          </div>
        ))}
      </OpeningParagraph>
      <MembersSection>
        <div className="container">
          {props.data.members.edges.map(({ node }) => (
            <div key={node.id} className="members-grid">
              {node.MembershipsOrder && node.MembershipsOrder.map((item) => (
                <div key={item.contentful_id}>
                  <a href={item.memberUrl} target="_blank" rel="noopener noreferrer">
                    <GatsbyImage image={item.logo.gatsbyImageData} alt={item.logo.title} />
                  </a>
                </div>
              ))}
            </div>
          ))}
        </div>
      </MembersSection>
      <NewsletterCTA />
    </main>
  )
}

export default Partnerships

export const Head = (seo) => {
  const seoData = seo.data.partnershipPageData
  return (
    <>
      {seoData.edges.map(({ node }) => (
      <Seo key={node.id} title={node.seoTitle} description={node.seoDescription.seoDescription} image={node.seoImage.url} />
      ))}
    </>
  )
}

export const NewsletterContent = graphql`
  {
    partnershipPageData: allContentfulPartnerPage {
      edges {
        node {
          id
          pageTitle
          pageTitleParagraph {
            pageTitleParagraph
          }
          openingParagraph {
            openingParagraph
          }
          contactButtonText
          contactButtonUrl
          membershipParagraph {
            membershipParagraph
          }
          seoTitle
          seoDescription {
            seoDescription
          }
          seoImage {
            url
          }
        }
      }
    }
    partners: allContentfulPartnershipsOrder {
      edges {
        node {
          id
          PartnershipsOrder {
            title
            contentful_id
            partnerUrl
            logo {
              gatsbyImageData(width: 200, placeholder: BLURRED)
              title
            }
          }
        }
      }
    }
    members: allContentfulMembershipsOrder {
      edges {
        node {
          id
          MembershipsOrder {
            title
            contentful_id
            memberUrl
            logo {
              gatsbyImageData(width: 200, placeholder: BLURRED)
              title
            }
          }
        }
      }
    }
  }
`;